.buttonsContainer {
  display: 'flex';
  flex-direction: 'row';
}

.cancelButton {
  margin-top: 20px;
  margin-right: 10px;
}

.saveButton {
  margin-top: 20px;
}

.checkboxLabel > label:hover {
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.breadcrumbsText {
  color: var(--black);
  opacity: 0.75;
}

@import '~@labsavvyapp/ui-components/lib/theme/theme-variables.css';

.header {
  display: flex;
  height: 42px;
  align-items: center;
  background: var(--grayedWhite);
  padding: 0 20px;
}

.column {
  display: flex;
  font-size: 12px;
  width: calc(10% - 10px);
  margin-left: 20px;
}

.column:first-child {
  width: calc(67% - 20px);
  margin-left: 0;
}

.row {
  margin: 9px 0;
}

.row:hover {
  cursor: auto;
}

.deleteIcon {
  cursor: pointer;
  justify-content: flex-end;
}

.providerNameColumn {
  width: 40%;
  font-size: 12px;
}

.accountNumberColumn {
  width: 40%;
  font-size: 12px;
}

.accountNumberColumn:hover {
  text-decoration: underline;
  cursor: pointer;
}

.deleteColumn {
  width: 8%;
}

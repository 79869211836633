.containerPadding {
  padding: 20px;
}

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.row {
  height: auto;
  margin: auto;
  border-bottom: 1px solid var(--iron);
  padding: 8px 16px 9px;
  font-weight: 300;
}

.row:last-child {
  border: none;
}

.selected {
  position: relative;
  background-color: var(--skyBlue);
}

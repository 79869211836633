.row {
  margin: 9px 20px 0;
  flex-wrap: nowrap;
}

.column {
  display: flex;
  min-width: 120px;
  font-size: 12px;
  width: 100%;
}

.column:last-child {
  width: 120px;
  margin-left: 20px;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.filters {
  position: absolute;
  top: -62px;
  right: 0;
  display: flex;
  align-items: center;
  height: 37px;
}

.dropdownLabel {
  margin-right: 10px;
}

.dropdown {
  font-size: 12px;
  margin-right: 16px;
}

.search:global(.ui.search) {
  font-size: 12px;
  margin-right: 16px;
}

.root {
  display: flex;
  width: 300px;
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.logo {
  height: 42px;
}

.title {
  text-align: left;
}

.text {
  font-size: 15px;
  font-weight: 100;
  line-height: 1.33;
  text-align: left;
  color: var(--shark);
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.inputField {
  margin-bottom: 15px;
}

.loginButton {
  width: 100%;
}

.header {
  height: 73px;
  display: flex;
  box-shadow: 0 2px 3px 0 var(--mercury);
  background-color: var(--wildSand);
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  position: relative;
}

.pageTitle {
  margin-bottom: 0;
}

/* Filters */
.filters {
  display: flex;
}

.dropdownLabel {
  opacity: 0.5;
  font-size: 12px;
}

.searchDropdown {
  width: 145px;
  margin-left: 13px;
}

.search {
  width: 290px;
  margin-left: 20px;
  margin-right: 15px;
}

/* Tools */
.tools {
  display: flex;
  height: 80px;
  align-items: center;
  justify-content: flex-end;
  background-color: var(--wildSand);
  padding: 20px;
}

.laboratoryDropdown {
  width: 378px;
  margin-left: 14px;
}

/* Header */
.header {
  height: 73px;
  display: flex;
  box-shadow: 0 2px 3px 0 var(--mercury);
  background-color: var(--wildSand);
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.header :global(.page-title) {
  margin: 0;
}

/* Content */
.contentContainer {
  display: flex;
}

.columnProfile {
  flex: 0 0 auto;
  padding: 30px 30px 30px 20px;
}

.columnFields {
  flex: 1;
  padding: 30px 20px 30px 30px;
}

.resetPassword {
  color: var(--pink);
  font-size: 13px;
  font-weight: 700;
  letter-spacing: -0.2px;
  cursor: pointer;
  margin-top: 38px;
}

.input {
  max-width: 420px;
}

/* Buttons */
.buttonsContainer {
  margin-top: 30px;
}

.cancelButton {
  margin-left: 20px;
}

/* Modal */
.modal {
  height: 200px;
  display: flex;
  flex-direction: column;
}

.modalButtons {
  margin-top: auto;
  align-self: flex-end;
}

/* Header */
.header {
  height: 73px;
  display: flex;
  box-shadow: 0 2px 3px 0 var(--mercury);
  background-color: var(--wildSand);
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  /*changed z-index to 10 due to  labortory dropdown issue*/
  z-index: 10;
}

/* Header > Laboratory */
.laboratoryWrapper {
  display: flex;
  align-items: center;
}

.laboratoryDropdown {
  width: 280px;
  margin: 0 38px 0 13px;
}

/* Slide Panel */
.summary label,
.subtitle label {
  font-size: 14px;
  color: var(--black);
}

.subtitle {
  margin: 20px 0;
}

.details label {
  margin-top: 25px;
  font-size: 14px;
  color: var(--black);
}

.details {
  height: 100%;
}

/* Content */
.formContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Package Name and Price header */
.packageDetails {
  display: flex;
  background: var(--wildSand);
  padding: 15px 20px 20px 20px;
}

.columnPackageName,
.columnPackagePrice {
  display: flex;
}

.columnPackageName label,
.columnPackagePrice label {
  color: var(--black);
  font-size: 14px;
}

.columnPackageName input {
  font-weight: 700;
}

.columnPackagePrice input {
  font-weight: 700;
}

.columnNewCategory {
  align-items: flex-end;
  display: flex;
  float: right;
  height: 100%;
}

/* Content > CheckList container */
.checklistContainer {
  padding: 0 20px 20px 20px;
  overflow: auto;
  flex: 1;
}

/* Action buttons */
.buttonsContainer {
  display: flex;
  background: var(--wildSand);
  margin-top: auto;
  height: 79px;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
}

.buttonNewCategory {
  background: var(--white);
}

.buttonCancel {
  color: var(--black);
}

.buttonSavePackage {
  margin-left: 20px;
}

.buttonSavePackage:disabled {
  background: var(--mercury);
}

/* CheckList items */
.checkListTest {
  display: flex;
}

.checkListTestCode {
  width: 90px;
}

.categoryWrapper {
  background-color: var(--white);
  margin: 20px 0;
}

.loaderPackage {
  position: absolute;
  top: 43%;
  width: 100%;
}

.header {
  height: 73px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  background: var(--midWhite);
  border-bottom: 1px solid var(--alto);
  position: relative;
  box-shadow: 0 2px 3px 0 var(--alto);
}

.title {
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: var(--black);
}

.details {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  margin-left: 9px;
  border-radius: 4px;
  border: solid 2px var(--skyBlue);
}

.title {
  width: 100%;
  height: 40px;
  padding-left: 16px;
  display: flex;
  align-items: center;
  border-bottom: solid 1px var(--darkWhite);
  background-color: var(--midWhite);
  font-size: 14px;
  font-weight: 900;
}

.subTitle {
  width: 100%;
  height: 40px;
  padding-left: 16px;
  display: flex;
  align-items: center;
  border-bottom: solid 1px var(--darkWhite);
  font-size: 14px;
}

.list {
  flex: 1;
  overflow: auto;
}

.listItem {
  height: 24px;
  display: flex;
  align-items: center;
  padding: 0 16px;
}

.listItem:first-child {
  margin-top: 10px;
}

.code {
  width: 60px;
  margin-right: 20px;
}

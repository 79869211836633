@import '~@labsavvyapp/ui-components/lib/theme/theme-variables.css';

.header {
  display: flex;
  height: 42px;
  align-items: center;
  background: var(--grayedWhite);
  padding: 0 40px;
}

.column {
  display: flex;
  width: calc(100% / 3);
  min-width: 120px;
  font-size: 12px;
  margin-left: 20px;
  flex: 1;
}

.column:last-child {
  margin-right: 20px;
}

.imageNameColumn {
  display: flex;
  flex: 2;
  align-items: center;
}

.memberImage {
  margin-right: 25px;
}

.refreshLink {
  cursor: pointer;
}

.resultCode,
.orderCode {
  width: 60px;
  margin-right: 20px;
  font-weight: 900;
}

.resultName,
.orderName {
  width: 100%;
}

@import '~@labsavvyapp/ui-components/lib/theme/theme-variables.css';

.column {
  display: flex;
  font-size: 12px;
  margin-left: 20px;
}

.row .column {
  width: calc(20% - 12px);
}

/* Header */
.header {
  display: flex;
  height: 42px;
  align-items: center;
  background: var(--wildSand);
  padding: 0 20px;
}

.header .column {
  width: calc(20% - 20px);
}

.header .column:first-child {
  width: calc(40% - 45px);
}

/* Rows */
.row {
  height: 48px;
  margin: 9px 20px 0;
  border: solid 1px var(--darkWhite);
  border-left: 4px solid var(--offWhite);
  position: relative;
}

.row .column:first-child {
  width: calc(40% - 30px);
  margin-left: 0;
}

.row::after {
  display: block;
  content: ' ';
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  width: 5px;
  height: auto;
  background: var(--offWhite);
}

.status {
  text-transform: capitalize;
}

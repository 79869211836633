@import '~@labsavvyapp/ui-components/lib/theme/theme-variables.css';

.header {
  height: 73px;
  display: flex;
  align-items: center;
  padding: 20px;
  background: var(--midWhite);
  border-bottom: 1px solid var(--alto);
  position: relative;
  box-shadow: 0 2px 3px 0 var(--alto);
}

.form {
  background: var(--white);
  padding: 26px 20px;
  flex: 1;
}

.inputColumn {
  margin-top: 10px;
}

.buttons {
  display: flex;
  margin-top: 30px;
}

:global(.ui.form .field).submitButton {
  margin-right: 10px;
}

.roleDropDown {
  width: 205px;
}

.rolePermissions {
  display: flex;
}

.permissionsList {
  margin-right: 10px;
}

.cancelButton {
  margin-left: 10px;
}

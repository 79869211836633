.code {
  width: 60px;
  margin-right: 20px;
}

.selectedPlaceholder {
  height: 40px;
}

.selected {
  position: absolute;
  width: 100%;
  margin-top: -40px;
}

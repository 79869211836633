@import '~@labsavvyapp/ui-components/lib/theme/theme-variables.css';

/* Projects list header */
.projectsListHeader {
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.pageTitle {
  font-size: 24px;
  font-weight: 900;
  margin: 0;
}

.tools {
  display: flex;
}

.dropDown {
  margin-left: 13px;
}

.search {
  width: 290px;
  margin-left: 20px;
  margin-right: 15px;
}

.contentContainer {
  display: flex;
  flex: 1;
}

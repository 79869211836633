@import '~@labsavvyapp/ui-components/lib/theme/theme-variables.css';

.container {
  display: flex;
  flex: 1;
  overflow: auto;
}

.subPageContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 14px 20px 0;
  background: var(--white);
  overflow: auto;
  width: 100%;
}

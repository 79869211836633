.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--darkPurple);
}

.title {
  margin-bottom: 40px;
}

.title,
.text {
  color: var(--midWhite);
}

.text {
  width: 400px;
  font-size: 15px;
}

.button {
  margin-top: 40px;
}

.container {
  display: flex;
  flex-flow: column;
  flex: 1;
  position: relative;
  padding: 14px 16px 20px;
  background: var(--lightGrey);
}

.searchContainer {
  display: flex;
}

.searchInput {
  width: 100%;
  margin-right: 20px;
}

.newButton {
  min-width: 140px;
}

.sectionsContainer {
  display: flex;
  flex: 1 0 auto;
  padding: 10px 0;
}

.section {
  display: flex;
  width: 50%;
  border-radius: 4px;
  border: 1px solid var(--darkWhite);
  padding: 10px;
  background: var(--white);
}

.section:not(:last-child) {
  margin-right: 10px;
}

@import '~@labsavvyapp/ui-components/lib/theme/theme-variables.css';

.header {
  display: flex;
  height: 42px;
  align-items: center;
  background: var(--grayedWhite);
  padding: 0 40px;
}

.codeColumn {
  display: flex;
  width: 10%;
  font-size: 12px;
  min-width: 6rem;
}

.nameColumn {
  display: flex;
  min-width: 6rem;
  width: 51%;
  font-size: 12px;
}

.lsCostColumn {
  display: flex;
  min-width: 6rem;
  width: 13%;
  font-size: 12px;
}

.lsStandardPriceColumn {
  display: flex;
  min-width: 6rem;
  width: 13%;
  font-size: 12px;
}

.lsStPrcColumn {
  display: flex;
  min-width: 6rem;
  width: 13%;
  font-size: 12px;
}

.lsStRtPriceColumn {
  display: flex;
  min-width: 6rem;
  width: 13%;
  font-size: 12px;
}

.Column {
  display: flex;
  min-width: 8rem;
  width: calc(100% / 6);
  font-size: 12px;
}

.rightColumn {
  display: flex;
  min-width: 8rem;
  width: 8rem;
  font-size: 12px;
  padding: 0 1rem;
  right: 0;
}

.leftColumn {
  display: flex;
  min-width: 10rem;
  width: 10.5rem;
  font-size: 12px;
  padding: 0 1rem;
}

.column {
  display: flex;
  width: calc(100% / 4);
  min-width: 120px;
  font-size: 12px;
  margin-left: 20px;
  flex: 1;
}

.column:last-child {
  margin-right: 20px;
  margin-left: 0;
}

.memberImage {
  margin-right: 25px;
}

.refreshLink {
  cursor: pointer;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

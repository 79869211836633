.modal {
  width: 871px;
  height: 465px;
  padding: 20px;
}

.container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.listDetailsContainer {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

/* Filters */
.filters {
  display: flex;
  margin-bottom: 10px;
}

.dropdown:global(.ui.selection.dropdown) {
  min-width: 135px;
  margin-right: 10px;
}

.search {
  width: 100%;
}

/* List */
.list {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  margin-right: 9px;
  border-radius: 4px;
  border: solid 1px var(--darkWhite);
}

.listFullWidth {
  width: 100%;
}

.listItem {
  display: flex;
  align-items: center;
  height: 40px;
  border-bottom: solid 1px var(--darkWhite);
  padding: 0 16px;
  cursor: pointer;
}

.selected {
  background: var(--skyBlue);
}

.disabled {
  cursor: default;
  color: var(--lightenBlack);
}

/* Buttons */
.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.cancelButton {
  margin-right: 10px;
  color: var(--black);
}

.addGroupButton {
  background: var(--skyBlue);
}

.header {
  height: 73px;
  display: flex;
  box-shadow: 0 2px 3px 0 var(--mercury);
  background-color: var(--wildSand);
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.pageTitle {
  font-size: 24px;
  font-weight: 900;
  margin: 0;
}

.tools {
  display: flex;
}

.dropdownLabel {
  opacity: 0.5;
  font-size: 12px;
}

.dropdown {
  margin-left: 13px;
}

.dropdown.ui.selection.dropdown {
  min-width: 135px;
}

.search {
  width: 290px;
  margin-left: 20px;
  margin-right: 15px;
}

.contentContainer {
  height: 100%;
}

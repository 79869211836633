.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.header {
  width: auto;
  min-height: 60px;
  display: flex;
  background-color: var(--white);
  border-bottom: 1px solid var(--darkWhite);
}

.contentHolder {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  flex: 1;
  overflow: auto;
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  overflow: auto;
}

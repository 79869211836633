.header {
  height: 73px;
  display: flex;
  box-shadow: 10px 23px 10px 0 var(--mercury);
  background-color: #f8f8f8;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.header :global(.page-title) {
  margin: 0;
}

.tools {
  display: flex;
}

.dropdown {
  width: 280px;
  margin-left: 13px;
}

.search {
  width: 290px;
  margin-left: 20px;
  margin-right: 15px;
}

.settingsCategory {
  padding: 10px 10px 10px 20px;
  cursor: pointer;
}

.settingsCategory:hover {
  background-color: #ececeb;
  font-weight: 900;
}

.settingsCategoryActive {
  padding: 10px 10px 10px 20px;
  cursor: pointer;
  background-color: #ececeb;
  font-weight: 900;
}

.settingsPageContainer {
  flex-direction: row;
  display: flex;
}

.settingsMenuList {
  flex-direction: column;
  background-color: #fff;
  width: 15%;
  height: 100%;
  overflow: auto;
}

.settingsMainArea {
  flex-direction: column;
  background-color: #fff;
  width: 85%;
  padding: 20px 0px 0px 20px;
  /* overflow-y: scroll; */
}

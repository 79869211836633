@import '~@labsavvyapp/ui-components/lib/theme/theme-variables.css';

.header {
  display: flex;
  height: 42px;
  align-items: center;
  background: var(--grayedWhite);
  padding: 0 40px;
  flex-wrap: nowrap;
}

.column {
  display: flex;
  min-width: 120px;
  font-size: 12px;
  width: 100%;
}
.column:last-child {
  width: 152px;
  margin-left: 0;
}
.notFoundMessage {
  margin-left: 20px;
}

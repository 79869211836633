@import '~@labsavvyapp/ui-components/lib/theme/theme-variables.css';

.header {
  height: 73px;
  display: flex;
  align-items: center;
  padding: 20px;
  background: var(--midWhite);
  border-bottom: 1px solid var(--alto);
  position: relative;
  box-shadow: 0 2px 3px 0 var(--alto);
  justify-content: space-between;
}

:global(.ui.grid).packageCategoriesGrid {
  background: var(--white);
  margin: 16px 10px;
  flex: 1;
}

:global(.ui.grid).packageCategoriesGrid > :global(.row) {
  padding: 0;
}

.inputColumn {
  margin-top: 20px;
}

.iconColumn {
  margin-top: 40px;
}

.summary {
  height: 200px;
}

.details {
  height: 525px;
}

.details[class*='TextEditor'] > [class*='TextEditor'],
.summary[class*='TextEditor'] > [class*='TextEditor'] {
  overflow-y: auto;
}

.buttons {
  display: flex;
}

.cancelButton {
  margin-right: 10px;
}

.deleteButton {
  color: var(--redOrange);
  padding: 0;
}

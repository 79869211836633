.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}

.title {
  margin-top: 20px;
}

.subtitle {
  margin-top: 0;
  font-size: 18px;
}

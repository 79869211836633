.row {
  margin: 9px 20px 0;
  flex-wrap: nowrap;
  padding-left: 10px;
}

.column {
  display: flex;
  min-width: 120px;
  font-size: 12px;
  width: 100%;
}

.iconColumn {
  align-items: center;
  border-right: 1px solid var(--darkWhite);
  display: flex;
  height: 100%;
  margin-right: 19px;
  width: 60px;
}

.iconImg {
  margin-right: 10px;
  width: 50px;
}

.column:last-child {
  width: 120px;
  margin-left: 20px;
}

.container {
  display: flex;
}

.container .row {
  border: 0;
  height: auto;
  margin: 0;
}

.viewInvoiceButton {
  color: var(--dodgerBlue);
}

.dateColumn {
  width: 85px;
}

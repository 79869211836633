.passwordField {
  display: flex;
  position: relative;
}

.inputField input {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid var(--darkWhite);
  background-color: var(--white);
  color: var(--black);
}

.icon {
  position: absolute;
  top: 8px;
  right: 10px;
}

.row {
  margin: 9px 20px 0;
}

.column {
  display: flex;
  width: calc(100% / 3);
  min-width: 120px;
  font-size: 12px;
  margin-left: 20px;
  flex: 1;
}

.column:last-child {
  margin-right: 20px;
}

.imageNameColumn {
  display: flex;
  flex: 2;
  align-items: center;
}

.memberImage {
  margin-right: 25px;
}

@import '~@labsavvyapp/ui-components/lib/theme/theme-variables.css';

.header {
  display: flex;
  height: 42px;
  align-items: center;
  background: var(--grayedWhite);
  padding: 0 40px;
  flex-wrap: nowrap;
}

.notFoundMessage {
  margin-left: 20px;
}

.row {
  margin: 9px 20px 0;
  flex-wrap: nowrap;
}

.columnCode {
  display: flex;
  max-width: 200px;
  font-size: 12px;
  width: 100%;
}

.columnName {
  display: flex;
  min-width: 120px;
  font-size: 12px;
  width: 100%;
}

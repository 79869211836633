.row {
  margin: 9px 20px 0;
  display: flex;
  padding: 0 0.5rem;
}

.row::after {
  display: none;
}

input {
  min-height: 40px;
  width: 100%;
  outline: 0;
  border-radius: 4px;
  font-size: 14px;
  border: 1px solid var(--darkWhite);
  padding: 10px;
  color: var(--black);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.firstColumn {
  display: flex;
  width: 10%;
  font-size: 12px;
  min-width: 6rem;
  font-weight: bold;
  padding-left: 1rem;
}

.secondColumn {
  display: flex;
  min-width: 6rem;
  width: 30%;
  font-size: 12px;
  font-weight: bold;
  justify-content: left;
}

.formColumn {
  display: flex;
  min-width: 6rem;
  width: 12%;
  font-size: 12px;
  justify-content: center;
  padding: 0 0.5rem;
  font-weight: bold;
}

.override {
  border-color: black;
}

.column {
  display: flex;
  width: calc(100% / 3);
  min-width: 120px;
  font-size: 12px;
  margin-left: 20px;
  flex: 1;
}

.column:last-child {
  margin-right: 20px;
}

.imageNameColumn {
  display: flex;
  flex: 2;
  align-items: center;
}

.leftColumns {
  display: flex;
}

.rightColumns {
  display: flex;
}

.memberImage {
  margin-right: 25px;
}

.columnPackageName,
.columnPackagePrice {
  display: flex;
}

.columnPackageName label,
.columnPackagePrice label {
  color: var(--black);
  font-size: 14px;
}

.columnPackageName input {
  font-weight: normal;
}

.columnPackagePrice input {
  font-weight: normal;
}

@import '~@labsavvyapp/ui-components/lib/theme/theme-variables.css';

.column:not(:first-child) {
  display: flex;
  width: calc(20% - 20px);
  font-size: 12px;
  margin-left: 20px;
}

.column:last-child {
  margin-right: 20px;
}

.organizationColumn {
  display: flex;
  align-items: center;
  width: calc(40% - 20px);
  font-size: 12px;
}

/* Header */
.header {
  display: flex;
  height: 42px;
  align-items: center;
  background: var(--grayedWhite);
  padding: 0 40px;
  flex-wrap: nowrap;
  font-size: 12px;
}

.header .column {
  margin-left: 20px;
}

.header .column:first-child {
  width: calc(40% - 45px);
}

/* Rows */
.row {
  height: 48px;
  margin: 9px 20px 0;
  border: solid 1px var(--darkWhite);
  border-left: 4px solid var(--offWhite);
  position: relative;
}

.row::after {
  display: block;
  content: ' ';
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  width: 5px;
  height: auto;
  background: var(--offWhite);
}

.memberImage {
  margin-right: 25px;
}

.status {
  text-transform: capitalize;
}

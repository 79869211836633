/* Header */
.header {
  height: 73px;
  display: flex;
  box-shadow: 0 2px 3px 0 var(--mercury);
  background-color: var(--wildSand);
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.header :global(.page-title) {
  margin: 0;
}

/* Content */
.contentContainer {
  display: flex;
  flex-direction: column;
  padding: 25px 20px;
}

.actionText {
  font-size: 16px;
  font-weight: 700;
  color: var(--black);
}

.verificationCodeInput {
  margin-top: 25px;
}

/* Content > Buttons */
.buttonsContainer {
  padding-top: 30px;
}

.cancelButton {
  margin-left: 10px;
}

/* Content > Resend code */
.resendCode {
  padding-top: 40px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: 1px;
  color: var(--purple);
  cursor: pointer;
}

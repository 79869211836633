.header {
  height: 73px;
  display: flex;
  box-shadow: 10px 23px 10px 0 var(--mercury);
  background-color: #f8f8f8;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.header :global(.page-title) {
  margin: 0;
}

.tools {
  display: flex;
}

.dropdown {
  width: 280px;
  margin-left: 13px;
}

.search {
  width: 290px;
  margin-left: 20px;
  margin-right: 15px;
}

.settingsCategory {
  padding: 10px 10px 10px 20px;
  cursor: pointer;
}

.settingsCategory:hover {
  background-color: #fff;
  font-weight: 900;
}

/* List Row styling */
.firstColumn {
  display: flex;
  width: 10%;
  font-size: 12px;
  min-width: 6rem;
}

.secondColumn {
  display: flex;
  min-width: 6rem;
  width: 51%;
  font-size: 12px;
}

.thirdColumn {
  display: flex;
  min-width: 6rem;
  width: 13%;
  font-size: 12px;
}

.fourthColumn {
  display: flex;
  min-width: 6rem;
  width: 13%;
  font-size: 12px;
}

.fifthColumn {
  display: flex;
  min-width: 6rem;
  width: 13%;
  font-size: 12px;
}

.row {
  margin-bottom: 1px;
}
.rowRed {
  background-color: #ffe8e6;
}

.column {
  display: flex;
  font-size: 12px;
  min-width: 6rem;
}

.ml5 {
  margin-left: 5px;
}

.amount {
  cursor: default;
  margin-right: 10px;
}
.amount > input {
  border: 0 !important;
}

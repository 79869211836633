@import '~@labsavvyapp/ui-components/lib/theme/theme-variables.css';

.card {
  width: 351px;
  height: 240px;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid var(--alto);
}

.title {
  font-size: 14px;
  font-weight: 900;
  margin-bottom: 20px;
}

.paragraph {
  font-size: 14px;
}

.container {
  display: flex;
}

.plan {
  display: flex;
  flex-direction: column;
}

.planDescriptionContainer {
  background: var(--purple);
  color: var(--white);
  border-radius: 20px;
  padding: 22px 30px;
  margin-bottom: 13px;
}

.planTitle {
  font-size: 20px;
  font-weight: 900;
  margin-bottom: 20px;
}

.planItem {
  font-size: 15px;
  margin-bottom: 4px;
  text-align: center;
}

.container {
  display: flex;
  width: 315px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.logo {
  width: 125px;
}

.appTitle {
  color: var(--purple);
  text-align: right;
}

.rememberMe {
  font-size: 14px;
  color: var(--shark);
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.forgotPasswordLink {
  color: var(--shark);
  font-size: 14px;
}

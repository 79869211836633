.packageCategoryPanelContainer {
  background-color: var(--white);
  border: 1px solid var(--darkWhite);
  padding: 0 20px 20px;
}

.packageCategoryPanelHeader {
  align-items: center;
  display: flex;
  border-bottom: 1px solid var(--darkWhite);
  padding: 15px 0 20px;
}

.columnSelect,
.columnDelete {
  align-items: center;
  display: flex;
  padding-right: 30px;
  width: 100%;
}

.packageCategorySelect {
  font-size: 18px;
  font-weight: 700;
  margin: 0 38px 0 0;
  width: 420px;
}

/* stylelint-disable */
.packageCategorySelect :global(.ui.selection.active.dropdown .menu) {
  border: none;
}

.packageCategorySelect :global(.ui.selection.dropdown) {
  border: none;
  border-bottom: 1px solid;
  border-radius: 0;
  padding: 9px 0 0;
  min-height: 35px;
}

.packageCategorySelect :global(.ui.selection.dropdown > .dropdown.icon) {
  padding: 10px 2px;
}
/* stylelint-enable */

.packageCategoryText {
  font-size: 18px;
  font-weight: 700;
  margin: 0 38px 0 0;
  width: 100%;
}

.packageCategoryText:hover {
  background-color: var(--lightPink);
}

.columnPlusButton {
  display: flex;
  align-items: flex-end;
}

.plusButton {
  background-color: var(--pageBackground);
  border: 1px solid var(--primaryColor);
  fill: var(--primaryColor);
  height: 28px;
  padding: 2px 0 0;
  width: 28px;
}

.saveButton {
  border-color: var(--black);
  color: var(--black);
  height: 28px;
  padding: 0 20px;
}

.deleteButton {
  font-family: var(--pageFont);
  position: relative;
  text-transform: none;
}

.deleteIcon {
  width: 10px;
}

.deleteText {
  bottom: 14px;
  color: var(--redOrange);
  display: inline-block;
  position: absolute;
  width: 125px;
}

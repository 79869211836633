@import '~@labsavvyapp/ui-components/lib/theme/theme-variables.css';

.header {
  min-height: 103px;
  background: var(--wildSand);
  box-shadow: 0 2px 3px 0 var(--mercury);
  display: flex;
  padding: 20px;
  font-size: 14px;
  z-index: 1;
}

/* Partner name */
.nameContainer {
  display: flex;
  flex-direction: column;
}

.name {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.3px;
  margin-top: 10px;
}

/* Partner data */
.dataContainer {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  line-height: 1.57;
  color: rgba(var(--rgbShark), 0.7);
  align-items: flex-end;
  margin-left: auto;
}

.dataRow {
  display: flex;
  justify-content: flex-end;
}

.dataLabel {
  font-weight: normal;
  margin-right: 10px;
}

.link {
  font-size: 15px;
  font-weight: 700;
  line-height: 1.57;
  margin-top: 10px;
  color: var(--dodgerBlue);
}

.link:hover {
  color: var(--dodgerBlue);
}

.status {
  text-transform: capitalize;
}

/* Partner details header > Partner logo */
.logoContainer {
  display: flex;
  flex: 0 0 auto;
}

.logoFrame {
  border: 1px solid var(--alto);
  background: var(--white);
  text-align: center;
  width: 259px;
  height: 60px;
}

.logo {
  padding: 5px;
  max-width: 100%;
  max-height: 100%;
}

.header {
  height: 73px;
  display: flex;
  box-shadow: 0 2px 3px 0 var(--mercury);
  background-color: var(--wildSand);
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  position: relative;
}

.header :global(.page-title) {
  margin: 0;
}

.filters {
  display: flex;
}

.tools {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--wildSand);
}

.toolsContainer {
  display: flex;
  padding: 20px;
}

.dropdownLabel {
  opacity: 0.5;
  font-size: 12px;
  padding: 10px 5px 0px 10px;
}

.dropdownSecondary {
  width: 50px;
  margin-left: 5px;
}

.search {
  width: 200px;
  margin-right: 15px;
}

.percentageSymbol {
  text-align: right;
  padding-left: 1rem;
}

.form {
  display: flex;
  height: 5.5rem;
}

.modal {
  max-width: 35rem;
}

.divider {
  margin: 0 1rem;
  display: flex;
  align-items: center;
}

.addButton {
  width: 2rem;
  height: 2rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;
}

.saveButton {
  width: 10rem;
  height: 2.3rem;
}

.cancelButton {
  color: var(--pink);
  font-size: 13px;
  font-weight: 700;
  text-transform: capitalize;
  width: 10rem;
  height: 2.3rem;
  letter-spacing: -0.2px;
}

.pricingInput input {
  padding-left: 18px;
  text-align: end;
}

.currency::before {
  position: relative;
  top: 2.2rem;
  width: 1rem;
  content: '$';
  left: 12px;
}

.percentage::before {
  position: relative;
  top: 2.2rem;
  width: 1rem;
  content: '%';
  left: 12px;
}

.importPricing {
  padding-top: 10px;
}

.importPricing:hover {
  color: var(--darkPurple);
}

.formContainer {
  width: 100%;
}

.infoMessage {
  padding: 16px;
  margin-bottom: 20px;
  border-radius: 3px;
  border: #fcab53 1px solid;
  color: #fcab53;
  font-weight: bold;
  background: #fcab5320;
}

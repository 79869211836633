.form {
  width: 100%;
}

.deleteButtonContainer {
  text-align: left;
}

.saveButtonContainer {
  text-align: right;
}

.deleteButton {
  padding-left: 0;
}

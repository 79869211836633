.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.filters {
  position: absolute;
  top: -62px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 14rem);
  height: 37px;
}

.printButton {
  width: 2rem;
  height: 2rem;
  padding: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: rgba(var(--rgbShark), 0.7);
}

.dropdownLabel {
  margin-right: 10px;
  font-size: 12px;
  margin-top: 5px;
}

.dropdown {
  font-size: 12px;
  margin-right: 16px;
}

.search:global(.ui.search) {
  font-size: 12px;
  margin-right: 16px;
}

.tools {
  display: flex;
}
